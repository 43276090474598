import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { environment } from "../../config";
import { AppRoutes } from "../../config/constants";


const Home = () => {

    const [type] = useQueryParam('type', StringParam);
    const navigate = useNavigate();
    const location = useLocation()


    useEffect(() => {

  
        if (type === 'unsubscribe') {
            navigate({
                pathname: AppRoutes.confirmUnsubscribe,
                search: location.search,
            });
        } else {
            if (environment === 'development') {
                console.log('dev enviroment')
                return
            }
            window.location.href = '/'
        }

    }, [type])

    return null


}


export default Home;